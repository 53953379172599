<template>
  <div class="users-view">
    <!--  <Component /> -->
  domains


  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'DoaminsView',
  components: {
//    'GroupsView': () => import('@/views/holon/GroupsView'),

  },
}
</script>
<style>
.users-view {
  text-align: left;
}
</style>
